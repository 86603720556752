import './style.scss'
import {useEffect, useState} from "react";
import PhoneInput from "react-phone-number-input";

const Phone = ({
                   label,
                   value,
                   onInput, mode = 'text',
                   required= false,
                   reset,
                   error = '',
                   placeholder = '',
                   defaultCountryIso2
               }) => {

    const [filled, setFilled] = useState(false)
    const [touched, setTouched] = useState(false)
    const inputHandler = (value) => {

        if(error) {
            reset()
        }

        onInput(value)
    }

    useEffect(() => {
        if(value) {
            setFilled(true)
            setTouched(true)
        } else {
            setFilled(false)
        }
    }, [filled]);

    return(
        <div className={'input-group'}>
            <div className="label-group">
                <div className="label">{label}</div>
                {error && <div className={'error-table'}><span>{error}</span></div>}
            </div>
            <div className="input-block">
                <PhoneInput onChange={(value) => inputHandler(value)}
                            onFocus={reset}
                            defaultCountry={defaultCountryIso2}
                            international
                />
            </div>
        </div>
    )
}

export default Phone