import {Link, Outlet} from "react-router-dom"
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import RequestForm from "../../components/RequestForm";
import logo from '../../assets/images/buildings.png'
import name from '../../assets/images/name_in_bar.png'
import LocaleSelector from "../../components/LocaleSelector";
import MobileViewLayout from "./MobileViewLayout";
import DesctopViewLayout from "./DesctopViewLayout";

import {store} from "../../storage";
import SlidingContainer from "../../components/SlidingContainer";

const MainLayout = () => {

    const {isMobile, screen} = useSelector(store => store.app)
    const {active} = useSelector(store => store.form)

    useEffect(() => {
        if (screen.x < 768) {
            import('./assets/index-responsive.scss');
        }
    }, [isMobile, screen.x])

    return(
        <div className={'main-layout'}>
            <main>
                <div className="left-bar">
                    <div className="logo">
                        <img src={logo} alt="AVA Real Estate"/>
                    </div>
                    <LocaleSelector />
                    <div className="name-bar"><img src={name} alt=""/></div>
                </div>
                {screen.x <= 768 ? <MobileViewLayout /> : <DesctopViewLayout />}
            </main>
        </div>
    )
}

export default MainLayout