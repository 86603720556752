import { createSlice } from '@reduxjs/toolkit'

export const formSlice = createSlice({
    name: 'form',
    initialState: {
        active: false,
        dropdown:false,
        countries: [
            {en: "Afghanistan", ru: "Афганистан", code: "afg"},
            {en: "Albania", ru: "Албания", code: "alb"},
            {en: "Algeria", ru: "Алжир", code: "dza"},
            {en: "Andorra", ru: "Андорра", code: "and"},
            {en: "Angola", ru: "Ангола", code: "ago"},
            {en: "Antigua and Barbuda", ru: "Антигуа и Барбуда", code: "atg"},
            {en: "Argentina", ru: "Аргентина", code: "arg"},
            {en: "Armenia", ru: "Армения", code: "arm"},
            {en: "Australia", ru: "Австралия", code: "aus"},
            {en: "Austria", ru: "Австрия", code: "aut"},
            {en: "Azerbaijan", ru: "Азербайджан", code: "aze"},
            {en: "Bahamas", ru: "Багамы", code: "bhs"},
            {en: "Bahrain", ru: "Бахрейн", code: "bhr"},
            {en: "Bangladesh", ru: "Бангладеш", code: "bgd"},
            {en: "Barbados", ru: "Барбадос", code: "brb"},
            {en: "Belarus", ru: "Беларусь", code: "blr"},
            {en: "Belgium", ru: "Бельгия", code: "bel"},
            {en: "Belize", ru: "Белиз", code: "blz"},
            {en: "Benin", ru: "Бенин", code: "ben"},
            {en: "Bhutan", ru: "Бутан", code: "btn"},
            {en: "Bolivia", ru: "Боливия", code: "bol"},
            {en: "Bosnia and Herzegovina", ru: "Босния и Герцеговина", code: "bih"},
            {en: "Botswana", ru: "Ботсвана", code: "bwa"},
            {en: "Brazil", ru: "Бразилия", code: "bra"},
            {en: "Brunei Darussalam", ru: "Бруней", code: "brn"},
            {en: "Bulgaria", ru: "Болгария", code: "bgr"},
            {en: "Burkina Faso", ru: "Буркина-Фасо", code: "bfa"},
            {en: "Burundi", ru: "Бурунди", code: "bdl"},
            {en: "Cabo Verde", ru: "Кабо-Верде", code: "cpv"},
            {en: "Cambodia", ru: "Камбоджа", code: "khm"},
            {en: "Cameroon", ru: "Камерун", code: "cmr"},
            {en: "Canada", ru: "Канада", code: "can"},
            {en: "Central African Republic", ru: "Центральноафриканская Республика", code: "caf"},
            {en: "Chad", ru: "Чад", code: "tcd"},
            {en: "Chile", ru: "Чили", code: "chl"},
            {en: "China", ru: "Китай", code: "chn"},
            {en: "Colombia", ru: "Колумбия", code: "col"},
            {en: "Comoros", ru: "Коморы", code: "com"},
            {en: "Congo", ru: "Конго", code: "cog"},
            {en: "Congo, Democratic Republic of the", ru: "Демократическая Республика Конго", code: "cod"},
            {en: "Costa Rica", ru: "Коста-Рика", code: "cri"},
            {en: "Croatia", ru: "Хорватия", code: "hrv"},
            {en: "Cuba", ru: "Куба", code: "cub"},
            {en: "Cyprus", ru: "Кипр", code: "cyp"},
            {en: "Czech Republic", ru: "Чехия", code: "cze"},
            {en: "Denmark", ru: "Дания", code: "dnk"},
            {en: "Djibouti", ru: "Джибути", code: "dji"},
            {en: "Dominica", ru: "Доминика", code: "dma"},
            {en: "Dominican Republic", ru: "Доминиканская Республика", code: "dom"},
            {en: "Ecuador", ru: "Эквадор", code: "ecu"},
            {en: "Egypt", ru: "Египет", code: "egy"},
            {en: "El Salvador", ru: "Сальвадор", code: "slv"},
            {en: "Equatorial Guinea", ru: "Экваториальная Гвинея", code: "gnq"},
            {en: "Eritrea", ru: "Эритрея", code: "eri"},
            {en: "Estonia", ru: "Эстония", code: "est"},
            {en: "Eswatini", ru: "Эсватини", code: "swz"},
            {en: "Ethiopia", ru: "Эфиопия", code: "eth"},
            {en: "Fiji", ru: "Фиджи", code: "fji"},
            {en: "Finland", ru: "Финляндия", code: "fin"},
            {en: "France", ru: "Франция", code: "fra"},
            {en: "Gabon", ru: "Габон", code: "gab"},
            {en: "Gambia", ru: "Гамбия", code: "gmb"},
            {en: "Georgia", ru: "Грузия", code: "geo"},
            {en: "Germany", ru: "Германия", code: "deu"},
            {en: "Ghana", ru: "Гана", code: "gha"},
            {en: "Greece", ru: "Греция", code: "grc"},
            {en: "Grenada", ru: "Гренада", code: "grd"},
            {en: "Guatemala", ru: "Гватемала", code: "gtm"},
            {en: "Guinea", ru: "Гвинея", code: "gin"},
            {en: "Guinea-Bissau", ru: "Гвинея-Бисау", code: "gbs"},
            {en: "Guyana", ru: "Гайана", code: "guy"},
            {en: "Haiti", ru: "Гаити", code: "hti"},
            {en: "Honduras", ru: "Гондурас", code: "hnd"},
            {en: "Hungary", ru: "Венгрия", code: "hun"},
            {en: "Iceland", ru: "Исландия", code: "isl"},
            {en: "India", ru: "Индия", code: "ind"},
            {en: "Indonesia", ru: "Индонезия", code: "idn"},
            {en: "Iran", ru: "Иран", code: "irn"},
            {en: "Iraq", ru: "Ирак", code: "irq"},
            {en: "Ireland", ru: "Ирландия", code: "irl"},
            {en: "Israel", ru: "Израиль", code: "isr"},
            {en: "Italy", ru: "Италия", code: "ita"},
            {en: "Jamaica", ru: "Ямайка", code: "jam"},
            {en: "Japan", ru: "Япония", code: "jpn"},
            {en: "Jordan", ru: "Иордания", code: "jor"},
            {en: "Kazakhstan", ru: "Казахстан", code: "kaz"},
            {en: "Kenya", ru: "Кения", code: "ken"},
            {en: "Kiribati", ru: "Кирибати", code: "kir"},
            {en: "Korea, Democratic People's Republic of", ru: "КНДР", code: "prk"},
            {en: "Korea, Republic of", ru: "Южная Корея", code: "kor"},
            {en: "Kuwait", ru: "Кувейт", code: "kwt"},
            {en: "Kyrgyzstan", ru: "Киргизия", code: "kgz"},
            {en: "Laos", ru: "Лаос", code: "lao"},
            {en: "Latvia", ru: "Латвия", code: "lva"},
            {en: "Lebanon", ru: "Ливан", code: "lbn"},
            {en: "Lesotho", ru: "Лесото", code: "lsr"},
            {en: "Liberia", ru: "Либерия", code: "lbr"},
            {en: "Libya", ru: "Ливия", code: "LBY"},
            {en: "Liechtenstein", ru: "Лихтенштейн", code: "lie"},
            {en: "Lithuania", ru: "Литва", code: "ltu"},
            {en: "Luxembourg", ru: "Люксембург", code: "lux"},
            {en: "Madagascar", ru: "Мадагаскар", code: "mdg"},
            {en: "Malawi", ru: "Малави", code: "mwi"},
            {en: "Malaysia", ru: "Малайзия", code: "mys"},
            {en: "Maldives", ru: "Мальдивы", code: "mdv"},
            {en: "Mali", ru: "Мали", code: "mli"},
            {en: "Malta", ru: "Мальта", code: "mlt"},
            {en: "Marshall Islands", ru: "Маршалловы Острова", code: "mhl"},
            {en: "Mauritania", ru: "Мавритания", code: "mrt"},
            {en: "Mauritius", ru: "Маврикий", code: "mus"},
            {en: "Mexico", ru: "Мексика", code: "mex"},
            {en: "Micronesia (Federated States of)", ru: "Микронезия", code: "fsm"},
            {en: "Moldova (Republic of)", ru: "Молдова", code: "mda"},
            {en: "Monaco", ru: "Монако", code: "mco"},
            {en: "Mongolia", ru: "Монголия", code: "mng"},
            {en: "Montenegro", ru: "Черногория", code: "mne"},
            {en: "Morocco", ru: "Марокко", code: "mar"},
            {en: "Mozambique", ru: "Мозамбик", code: "moz"},
            {en: "Myanmar", ru: "Мьянма", code: "mmr"},
            {en: "Namibia", ru: "Намибия", code: "nam"},
            {en: "Nauru", ru: "Науру", code: "nru"},
            {en: "Nepal", ru: "Непал", code: "npl"},
            {en: "Netherlands", ru: "Нидерланды", code: "nld"},
            {en: "New Zealand", ru: "Новая Зеландия", code: "nzl"},
            {en: "Nicaragua", ru: "Никарагуа", code: "nic"},
            {en: "Niger", ru: "Нигер", code: "ner"},
            {en: "Nigeria", ru: "Нигерия", code: "nig"},
            {en: "North Macedonia", ru: "Северная Македония", code: "mkd"},
            {en: "Norway", ru: "Норвегия", code: "nor"},
            {en: "Oman", ru: "Оман", code: "omn"},
            {en: "Pakistan", ru: "Пакистан", code: "pak"},
            {en: "Palau", ru: "Палау", code: "plw"},
            {en: "Panama", ru: "Панама", code: "pan"},
            {en: "Papua New Guinea", ru: "Папуа - Новая Гвинея", code: "png"},
            {en: "Paraguay", ru: "Парагвай", code: "pry"},
            {en: "Peru", ru: "Перу", code: "per"},
            {en: "Philippines", ru: "Филиппины", code: "phl"},
            {en: "Poland", ru: "Польша", code: "pol"},
            {en: "Portugal", ru: "Португалия", code: "prt"},
            {en: "Qatar", ru: "Катар", code: "qat"},
            {en: "Romania", ru: "Румыния", code: "rou"},
            {en: "Russia", ru: "Россия", code: "rus"},
            {en: "Rwanda", ru: "Руанда", code: "rwa"},
            {en: "Saint Kitts and Nevis", ru: "Сент-Китс и Невис", code: "kna"},
            {en: "Saint Lucia", ru: "Сент-Люсия", code: "lca"},
            {en: "Saint Vincent and the Grenadines", ru: "Сент-Винсент и Гренадины", code: "vct"},
            {en: "Samoa", ru: "Самоа", code: "sam"},
            {en: "San Marino", ru: "Сан-Марино", code: "smr"},
            {en: "Sao Tome and Principe", ru: "Сан-Томе и Принсипи", code: "stp"},
            {en: "Saudi Arabia", ru: "Саудовская Аравия", code: "sau"},
            {en: "Senegal", ru: "Сенегал", code: "sen"},
            {en: "Serbia", ru: "Сербия", code: "srb"},
            {en: "Seychelles", ru: "Сейшельские Острова", code: "syc"},
            {en: "Sierra Leone", ru: "Сьерра-Леоне", code: "sle"},
            {en: "Singapore", ru: "Сингапур", code: "sgp"},
            {en: "Slovakia", ru: "Словакия", code: "svk"},
            {en: "Slovenia", ru: "Словения", code: "svn"},
            {en: "Solomon Islands", ru: "Соломоновы Острова", code: "slb"},
            {en: "Somalia", ru: "Сомали", code: "som"},
            {en: "South Africa", ru: "Южная Африка", code: "zaf"},
            {en: "South Sudan", ru: "Южный Судан", code: "ssd"},
            {en: "Spain", ru: "Испания", code: "esp"},
            {en: "Sri Lanka", ru: "Шри-Ланка", code: "lka"},
            {en: "Sudan", ru: "Судан", code: "sdn"},
            {en: "Suriname", ru: "Суринам", code: "sur"},
            {en: "Sweden", ru: "Швеция", code: "swe"},
            {en: "Switzerland", ru: "Швейцария", code: "che"},
            {en: "Syria", ru: "Сирия", code: "syr"},
            {en: "Taiwan", ru: "Тайвань", code: "twn"},
            {en: "Tajikistan", ru: "Таджикистан", code: "tjk"},
            {en: "Tanzania", ru: "Танзания", code: "tza"},
            {en: "Thailand", ru: "Таиланд", code: "tha"},
            {en: "Timor-Leste", ru: "Восточный Тимор", code: "tls"},
            {en: "Togo", ru: "Того", code: "tgo"},
            {en: "Tonga", ru: "Тонга", code: "ton"},
            {en: "Trinidad and Tobago", ru: "Тринидад и Тобаго", code: "tto"},
            {en: "Tunisia", ru: "Тунис", code: "tun"},
            {en: "Turkey", ru: "Турция", code: "tur"},
            {en: "Turkmenistan", ru: "Туркменистан", code: "tkm"},
            {en: "Tuvalu", ru: "Тувалу", code: "tuv"},
            {en: "Uganda", ru: "Уганда", code: "uga"},
            {en: "Ukraine", ru: "Украина", code: "ukr"},
            {en: "United Arab Emirates", ru: "Объединенные Арабские Эмираты", code: "are"},
            {en: "United Kingdom", ru: "Великобритания", code: "gbr"},
            {en: "United States of America", ru: "США", code: "usa"},
            {en: "Uruguay", ru: "Уругвай", code: "ury"},
            {en: "Uzbekistan", ru: "Узбекистан", code: "uzb"},
            {en: "Vanuatu", ru: "Вануату", code: "vut"},
            {en: "Venezuela", ru: "Венесуэла", code: "ven"},
            {en: "Vietnam", ru: "Вьетнам", code: "vnm"},
            {en: "Yemen", ru: "Йемен", code: "yem"},
            {en: "Zambia", ru: "Замбия", code: "zmb"},
            {en: "Zimbabwe", ru: "Зимбабве", code: "zwe"}
            ],
        fields: {
            name: '',
            phone: '',
            email: '',
            residence: '',
            readiness: '',
            type: '',
            when: '',
            budget: '',
            budget2: '',
            areas: '',
            info: '',
        },
        errors: {},
    },
    reducers: {
        setActive: (state) => {
            state.active = true
        },
        closeForm: (state) => {
            state.active = false
        },
        setDropdown: (state, {payload}) => {
            state.dropdown = !state.dropdown
        },
        removePosition: (state, {payload}) => {
            state.fields.positions = state.fields.positions.filter((k, index) => index !== payload)
        },
        addPosition: (state, {payload}) => {

            if(state.fields.positions.filter((k, index) => k.title === payload.title).length === 0) {
                state.fields.positions.push({title: payload.title, qty: 1, edit: false})
                state.dropdown = false
            }
        },
        setField: (state, {payload}) => {
            state.fields[payload.field] = payload.value
        },
        setErrors: (state, {payload}) => {
            state.errors = payload
        },
        unsetError: (state, {payload}) => {
            delete state.errors[payload]
        },
        resetForm: (state) => {
            state.fields = {
                name: '',
                phone: '',
                email: '',
                residence: '',
                interested: null,
                readiness: '',
                type: '',
                when: '',
                budget: '',
                budget2: '',
                areas: '',
                info: '',
            }
            state.errors = {}
        },
        fillTestData: (state) => {
            state.fields = {
                name: 'John Smith',
                phone: '+995591678675',
                email: 'john.s@gmail.com',
                positions: [{title: 'Web Developer'}]
            }
            state.errors = {}
        },
    }
})

export const {
    setActive,
    closeForm,
    setDropdown,
    removePosition,
    addPosition,
    setField,
    setErrors,
    unsetError,
    resetForm,
    fillTestData,
} = formSlice.actions
export default formSlice.reducer
