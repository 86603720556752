import './style.scss'
import {useEffect, useState} from "react";

const TextArea = ({label, value, onInput, error, required, lines = 3, max = 0}) => {

    const [filled, setFilled] = useState(false)
    const [touched, setTouched] = useState(false)
    const [left, setLeft] = useState(max)
    const inputHandler = (value) => {

        if(value.length <= max) {
            onInput(value)
        } else {

        }

        setLeft(max - value.length)
    }

    useEffect(() => {
        if(value) {
            setFilled(true)
            setTouched(true)
        } else {
            setFilled(false)
        }
    }, [filled]);

    return(
        <div className={'input-group textarea-group'}>
            <div className="label-group">
                <div className="label">{label}</div>
                {error && <div className={'error-table'}><span>{error}</span></div>}
            </div>
            <div className="textarea-block">
                <textarea
                    rows={3}
                    onChange={(event) => inputHandler(event.target.value)}
                    maxLength={max}
                ></textarea>
            </div>
            <div className="counter">{left}/{max}</div>
        </div>
    )
}

export default TextArea