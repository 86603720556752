import './style.scss'

const RadioButtonGroup = ({items, required, value, error, onSelect, classnames = '', reset}) => {

    const selectHandler = (value) => {
        reset()
        onSelect(value)
    }


    return(
        <div className={'radio-button-group-component'}>
            <div className="select-group">
                {items.map((i,k) =>
                    <div className={`item ${classnames}`} key={k}
                         onClick={() => selectHandler(i.value)}
                    >
                        <div className={`inner ${i.value === value ? 'active' : ''}`}>
                            <div className="radio-icon">
                                <div className="circle-outer">
                                    <div className="circle-inner"></div>
                                </div>
                            </div>
                            <div className="selection-label">
                                {i.label}
                            </div>
                        </div>
                    </div>)}
            </div>

        </div>
    )
}

export default RadioButtonGroup