import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    addPosition, closeForm, fillTestData,
    removePosition, resetForm,
    setActive,
    setDropdown,
    setErrors,
    setField,
    unsetError
} from "../../storage/FormReducer";
import Selector from "../Selector";
import 'react-phone-number-input/style.css'
import './style.scss'
import CoreHttp from "../../http/axios";
import ButtonLoading from "../ButtonLoading";
import Input from "../Input";
import RadioButtonGroup from "../RadioButtonGroup";
import TextAria from "../Input/TextAria";
import Phone from "../Input/Phone";

const RequestForm = () => {

    const d = useDispatch()
    const {locale, device, countryIso2, countryIso3} = useSelector(store => store.app)
    const {active, fields, errors, countries} = useSelector(store => store.form)
    const [loading, setLoading] = useState(false)
    const [thank, setThank] = useState(false)

    useEffect(() => {
        const bodyLock = document.querySelector('body')
        bodyLock.style.overflow = 'hidden'

        return(() => {
            bodyLock.removeAttribute("style");
        })
    }, [active]);

    useEffect(() => {

        if(!thank)
            return

        const tmThank = setTimeout(() => {
            setThank(false)
            d(closeForm())
        }, 3000)

        return(() => {
            clearTimeout(tmThank)
        })
    }, [thank]);

    const scrollToError = () => {
        const errorElement = document.querySelector(".error-table");

        if (errorElement) {
            errorElement.scrollIntoView({
                behavior: "smooth", // Плавная прокрутка
                block: "center",    // Центрирование на экране
            });
        }
    }

    const sendRequest = async () => {

        setLoading(true)

        try {
            const response = await CoreHttp.post('/leadstream/lead/new', fields)

            if(response.status === 200) {
                await setThank(true)
                await d(resetForm())
            }
        } catch (e) {
            if(e.response.status === 422) {
                d(setErrors(e.response.data))
                scrollToError()
            } else if(e.response.status === 400) {
                alert('Token error! Please, reload the page and try once again!')
            } else if(e.response.status === 403) {
                alert('Token forbidden! Please, reload the page and try once again!')
            } else {
                alert('Something went wrong!')
            }

            setLoading(false)
        }
    }

    const resetError = (key) => {
        if(errors[key]) {
            d(unsetError(key))

            if(key !== 'positions') {
                d(setField({field: key, value: ''}))
            }
        }
    }


    return (
        <div className={'request-form-component'}>

            {
                thank ?

                <div className={'thank-container'}>
                    <div className="thank-block">
                        <div className="icon">
                            <i className={'icon-check-bold'} />
                        </div>
                        <div className="text">
                            {
                                locale === 'en'
                                    ? <><div>Your request</div><div>has been sent</div></>
                                    : <><div>Ваш запрос</div><div>отправлен!</div></>
                            }
                        </div>
                    </div>
                </div> : <>
                    <div className="heading">
                        {locale === 'en'
                            ? <>
                                <h2>Leave a Request</h2>
                                <h3>Fill out this short form<br />and I will get in touch with you</h3>
                            </>
                            : <>
                                <h2>Оставьте запрос</h2>
                                <h3>Пожалуйста, заполните форму и я свяжусь с вами</h3>
                            </>
                        }
                    </div>

                    <div className="section field-group-section">
                        <div className="field-group">
                            <div className="field field__min-col-1 field__max-col-2">
                                <Input
                                    label={locale === 'en' ? 'Your Name' : 'Ваше имя'}
                                    onInput={value => d(setField({field: 'name', value: value}))}
                                    value={fields.name}
                                    error={errors.name}
                                    required={true}
                                    reset={() => resetError('name')}
                                />
                            </div>
                            <div className="field field__min-col-1 field__max-col-2">
                                <Phone
                                    label={locale === 'en' ? 'Phone Number' : 'Контактный телефон'}
                                    placeholder={'+995 123 456 789'}
                                    value={ fields.phone }
                                    onInput={ value => d(setField({field: 'phone', value: value})) }
                                    defaultCountryIso2={countryIso2}
                                    error={errors.phone}
                                    reset={() => resetError('phone')}
                                />
                            </div>
                            <div className="field field__min-col-1 field__max-col-2">
                                <Input
                                    label={'E-mail'}
                                    onInput={value => d(setField({field: 'email', value: value}))}
                                    value={fields.email}
                                    error={errors.email}
                                    required={true}
                                    reset={() => resetError('email')}
                                />
                            </div>
                            <div className="field field__min-col-1 field__max-col-2">
                                <Selector
                                    list={countries}
                                    label={locale === 'en' ? 'Country of Your Residence' : 'Страна вашего проживания'}
                                    onSelect={value => d(setField({field: 'residence', value: value}))}
                                    value={fields.residence}
                                    error={errors.residence}
                                    required={true}
                                    reset={() => resetError('residence')}
                                    defaultCountryIso3={countryIso3}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        {errors.readiness && <div className={'error-table'}><span>{errors.readiness}</span></div>}
                        {locale === 'en'
                            ? <h4>Are you looking for a ready property or one under construction?</h4>
                            : <h4>Вы ищете готовый объект или строящийся?</h4>
                        }

                        <RadioButtonGroup
                            required={true}
                            error={errors.readiness}
                            value={fields.readiness}
                            onSelect={value => d(setField({field: 'readiness', value: value}))}
                            items={[
                                {label: locale === 'en' ? 'Ready Property' : 'Готовый', value: 'Ready'},
                                {label: locale === 'en' ? 'Under Construction' : 'Строящийся', value: 'Under Construction'},
                            ]}
                            classnames={'item__min-col-1 item__max-col-2'}
                            reset={() => resetError('readiness')}
                        />
                    </div>

                    <div className="section">

                        {errors.type && <div className={'error-table'}><span>{errors.type}</span></div>}

                        {locale === 'en'
                            ? <h4>What type of property are you interested in?</h4>
                            : <h4>Какой тип недвижимости вас интересует?</h4>
                        }

                        <RadioButtonGroup
                            required={true}
                            error={errors.type}
                            value={fields.type}
                            onSelect={value => d(setField({field: 'type', value: value}))}
                            items={[
                                {label: locale === 'en' ? 'Apartment' : 'Апартаменты', value: 'Apartment'},
                                {label: locale === 'en' ? 'Townhouse' : 'Таунхаус', value: 'Townhouse'},
                                {label: locale === 'en' ? 'Villa' : 'Вилла', value: 'Villa'},
                                {label: locale === 'en' ? 'Mansion' : 'Особняк', value: 'Mansion'},
                            ]}
                            classnames={'item__min-col-2 item__max-col-2'}
                            reset={() => resetError('type')}
                        />
                    </div>

                    <div className="section">

                        {errors.when && <div className={'error-table'}><span>{errors.when}</span></div>}

                        {locale === 'en'
                            ? <h4>When do you plan to make the purchase?</h4>
                            : <h4>Когда вы планируете совершить покупку?</h4>
                        }

                        <RadioButtonGroup
                            required={true}
                            error={errors.when}
                            value={fields.when}
                            onSelect={value => d(setField({field: 'when', value: value}))}
                            items={[
                                {label: locale === 'en' ? 'Immediately' : 'Немедленно', value: 'Immediately'},
                                {label: locale === 'en' ? 'Within 3 months' : 'В течение 3 месяцев', value: 'Within 3 months'},
                                {label: locale === 'en' ? 'Within 3-6 months' : 'В течение 3-6 месяцев', value: 'Within 3-6 months'},
                                {label: locale === 'en' ? 'Within 6-12 months' : 'В течение 6-12 месяцев', value: 'Within 6-12 months'},
                                {
                                    label: locale === 'en' ? 'More than 1 year' : 'Более чем через 1 год',
                                    value: 'More than 1 year'
                                },
                            ]}
                            classnames={'item__min-col-2 item__max-col-2'}
                            reset={() => resetError('when')}
                        />
                    </div>

                    <div className="section">

                        {errors.budget && <div className={'error-table'}><span>{errors.budget}</span></div>}

                        {locale === 'en'
                            ? <h4>What is your budget (please specify in US)?</h4>
                            : <h4>Каков ваш бюджет (укажите в US)?</h4>
                        }

                        <RadioButtonGroup
                            required={true}
                            error={errors.budget}
                            value={fields.budget}
                            onSelect={value => d(setField({field: 'budget', value: value}))}
                            items={[
                                {label: locale === 'en' ? 'Up to 300K' : 'До 300K', value: '300000'},
                                {label: locale === 'en' ? '300K - 600K' : '300K - 600K', value: '600000'},
                                {label: locale === 'en' ? '600k - 1M' : '600k - 1M', value: '1000000'},
                                {
                                    label: locale === 'en' ? 'More than 1M' : 'Более 1M',
                                    value: '>1m'
                                },
                            ]}
                            classnames={'item__min-col-2 item__max-col-4'}
                            reset={() => resetError('budget')}
                        />

                        {fields.budget === '>1m' && <div className="specify"><Input
                            label={locale === 'en' ? 'Please, clarify your budget' : 'Пожалуйста, уточните ваш бюджет'}
                            onInput={value => d(setField({field: 'budget2', value: value}))}
                            value={fields.budget2}
                            error={errors.budget2}
                            required={true}
                            reset={() => resetError('budget2')}
                            placeholder={locale === 'en'
                                ? 'Insert amount more than 1 million US'
                                : 'Введите значение от 1 миллиона US'
                            }
                        /></div>}
                        </div>

                            <div className="section">
                        {locale === 'en'
                            ? <h4>Do you have any preferred areas or neighborhoods?</h4>
                            : <h4>Есть ли у вас предпочтительные районы или кварталы?</h4>
                        }

                        <div className="field-group">
                            <div className="field field__max-col-1">

                                <TextAria
                                    label={locale === 'en' ? 'Specify preferred areas (optional)' : 'Укажите предпочтительные районы (не обязательно)'}
                                    onInput={value => d(setField({field: 'areas', value: value}))}
                                    value={fields.areas}
                                    error={errors.areas}
                                    required={false}
                                    max={250}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="section field-group-section">
                        {locale === 'en'
                            ? <h4>Is there any additional information that could help us find the best option for you?</h4>
                            : <h4>Есть ли какая-нибудь дополнительная информация, которая поможет нам подобрать для вас оптимальный вариант?</h4>
                        }

                        <div className="field-group">
                            <div className="field field__max-col-1">
                                <TextAria
                                    label={locale === 'en' ? 'Additional Information (optional)' : 'Дополнительная информация (не обязательно)'}
                                    onInput={value => d(setField({field: 'info', value: value}))}
                                    value={fields.info}
                                    error={errors.info}
                                    required={false}
                                    max={250}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="controls">
                        <ButtonLoading sendFoo={sendRequest} loading={loading} />
                    </div>
                </>
            }
        </div>
    )
}
export default RequestForm