import {memo, useEffect} from "react";
import Router from "./Router";
import {useDispatch, useSelector} from "react-redux";
import {setCountrySource, setScreen, setScroll, setUtm} from "./storage/AppReducer";
import {getScrollbarWidth, textResize} from "./functions";
import './assets/icon-fonts/style.css'
import CoreHttp from "./http/axios";
import {resetForm} from "./storage/FormReducer";


const App = memo(function App() {
  const d = useDispatch()
  const {isDesktop, isTablet, isMobile, utm} = useSelector(state => state.app)

  document.addEventListener('wheel', function(e) {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  }, { passive: false });

  document.addEventListener('keydown', function(e) {
    if (e.ctrlKey && (e.key === '=' || e.key === '-' || e.key === '0')) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    onResize()
    textResize()
    initRequest()
    d(setUtm())

    window.addEventListener("resize", onResize)
    window.addEventListener("scroll", onScroll)
    window.addEventListener("resize", textResize)
    window.addEventListener('load', onResize)


    // window.gtag('event', 'visit_page', {
    //   'locale': locale,
    //   'device': device,
    // });
    // console.log('visit_page')

    return () => {
      window.removeEventListener("resize", onResize)
      window.removeEventListener("scroll", onScroll)
      window.addEventListener("resize", textResize)
      window.addEventListener("load", onResize)
    }
  }, [])

  const initRequest = async () => {
    try {
      const response = await CoreHttp.get('/leadstream/lead/csrf')

      if(response.status === 200) {
        const {iso2, iso3} = response.data

        const diso2 = iso2 ? iso2 : 'US'
        const diso3 = iso3 ? iso3 : 'usa'

        d(setCountrySource({iso2: diso2, iso3: diso3}))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onResize = () => {
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    const headerComponent = document.querySelector('.header-component')
    const documentHeight = document.documentElement.scrollHeight;
    let windowHeight = window.innerHeight
    let innerWidth = window.innerWidth
    let screenWidth = window.screen.availWidth
    let screenHeight = window.screen.availHeight
    let scrollActive = 0

    if (documentHeight > windowHeight) {
      scrollActive = getScrollbarWidth()
    } else {
      scrollActive = 0
    }

    let baseWidth = screenWidth - scrollActive
    let container = document.body
    let mainElement = document.querySelector('main')
    let scale = innerWidth / baseWidth
    let actualHeight = screenHeight <= windowHeight
        ? screenHeight
        : windowHeight

    // mainElement.style.height = actualHeight + 'px'

    if(isDesktop) {
      // mainElement.style.transform = `scale(${scale})`
      // mainElement.style.transformOrigin = 'top left'
      // mainElement.style.width = `${baseWidth}px`

      if(headerComponent) {
        // headerComponent.style.transform = `scale(${scale})`
        // headerComponent.style.transformOrigin = 'top left'
        // headerComponent.style.width = `${baseWidth}px`
      }

    } else if(isTablet) {
      // if(baseWidth < 768) {
      //   viewportMeta.setAttribute('content', 'initial-scale=1, maximum-scale=1');
      // } else if (baseWidth >= 768 && baseWidth < 1024) {
      //   viewportMeta.setAttribute('content', 'initial-scale=0.5, maximum-scale=1');
      // } else {
      //   viewportMeta.setAttribute('content', 'initial-scale=0.6, maximum-scale=1');
      // }
    } else if(isMobile) {
      // viewportMeta.setAttribute('content', 'initial-scale=1, maximum-scale=1');
    } else {
      container.style.transform = `scale(1)`
    }

    d(setScreen({x:baseWidth, y:screenHeight}))
  }

  const onScroll = () => {
    d(setScroll({y: window.scrollY}))
  }

  return <Router />
})

export default App
