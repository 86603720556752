import axios from "axios";
import {useSelector} from "react-redux";
import {store} from "../storage";

const CoreHttp = axios.create({
    baseURL: process.env.REACT_APP_PROCESSING_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
    },
    withXSRFToken: true,
    withCredentials: true,
});
CoreHttp.interceptors.request.use(
    (config) => {
        const state = store.getState(); // Достаём состояние из Redux

        // Инициализируем параметры, если они отсутствуют
        const formData = config.data;

        // Добавляем UTM-метки из состояния
        config.data = {
            ...formData, // Существующие параметры
            ...state.app.utm, // Параметры UTM
        };

        return config; // Возвращаем обновлённую конфигурацию
    },
    (error) => {
        // Обрабатываем ошибки запроса
        return Promise.reject(error);
    }
);

export default CoreHttp