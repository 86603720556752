import './style.scss'
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {store} from "../../storage";
const ButtonLoading = ({loading, sendFoo}) => {

    const {locale} = useSelector(store => store.app)

    return(
        <div className={'button-loading-component'}>
            <div className={`send-button ${locale === 'en' ? 'locale-en' : 'locale-ru'}`}>
                {!loading
                    ? <button onClick={sendFoo}>{locale === 'en' ? 'Send Request' : 'Отправить'}</button>
                    : <div className="loader"></div>
                }
            </div>

        </div>
    )
}

export default ButtonLoading