import {useRef, useEffect, useState} from "react";
import './style.scss'
import {useSelector} from "react-redux";
import {store} from "../../storage";
import Input from "../Input";
import {setField} from "../../storage/FormReducer";

const Selector = ({label, list, error, value, required, onSelect, reset, defaultCountryIso3 = ''}) => {
    const boxRef = useRef()

    const {locale} = useSelector(store => store.app)
    const [dropdown, setDropdown] = useState(false)

    useEffect(() => {
        if(defaultCountryIso3) {
            const def = list.filter(i => i.code === defaultCountryIso3)
            selectHandler(def[0]['en'])
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickListener);

        return () => {
            document.removeEventListener('mousedown', handleClickListener);
        };
    }, [boxRef]);

    const handleClickListener = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setDropdown(!dropdown)
        }
    };

    const selectHandler = (value, dropdownAction = false) => {
        reset()
        onSelect(value)
        dropdownAction && setDropdown(!dropdown)
    }

    return(
        <div className={`selector-component ${dropdown ? 'selector-active' : ''}`}>

            <div className={'input-group'} onClick={() => setDropdown(!dropdown)}>
                <div className="label-group">
                    <div className="label">{label}</div>
                    {error && <div className={'error-table'}><span>{error}</span></div>}
                </div>
                <div className="input-block">
                    <div className="input-faked input-field">
                        <div className="value">{value}</div>
                        <div className="btn-icon">
                            <i className={'icon-arrow-slider'}/>
                        </div>
                    </div>
                </div>
            </div>

            {dropdown && <div className="dropdown" ref={boxRef}>
                <div className="list scrollable">
                    <ul>
                        {list.map((i, k) => {
                            return (
                                <li key={k} onClick={() => selectHandler(i['en'], true)}>{i[locale]}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>}
        </div>
    )
}

export default Selector