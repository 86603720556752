import './style.scss'
import {useEffect, useState} from "react";

const Input = ({
                   label,
                   value,
                   onInput, mode = 'text',
                   required= false,
                   reset,
                   error = '',
                   placeholder = ''
    }) => {

    const [filled, setFilled] = useState(false)
    const [touched, setTouched] = useState(false)
    const inputHandler = (value) => {
        onInput(value)
    }

    useEffect(() => {
        if(value) {
            setFilled(true)
            setTouched(true)
        } else {
            setFilled(false)
        }
    }, [filled]);

    return(
        <div className={'input-group'}>
            <div className="label-group">
                <div className="label">{label}</div>
                {error && <div className={'error-table'}><span>{error}</span></div>}
            </div>
            <div className="input-block">
                <input type="text" value={value}
                       onFocus={reset}
                       onChange={(event) => inputHandler(event.currentTarget.value)}
                       placeholder={placeholder}
                />
            </div>
        </div>
    )
}

export default Input